import React from "react";
import { Link, graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";
import { UiUxDesignerJsonLd } from "../components/jsonld/UiUxDesignerJsonLd";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
// import IconCheck from "../images/icons/interface/check.svg";

const SenioruiuxdesignerPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    <UiUxDesignerJsonLd />
    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                Senior Product Designer / UI UX designer
                <br />
                Full-Time - 100% Remote
              </h1>

              <Link
                to="https://adamfard.freshteam.com/jobs/R5LbBFdn3r-s/senior-product-designer-ux-ui-remote#applicant-form"
                className="btn btn-lg btn-primary lead-cta"
                target="_blank"
              >
                Apply Now
              </Link>

              <h2 className="mt-6 mb-3">Your journey and Responsibilities</h2>
              <p className="lead"><strong>You gather requirements from clients and stakeholders</strong> and know how to share the UX ownership. <strong>You collaborate closely with stakeholders</strong> and keep them involved in the design process;</p>
              <p className="lead">You know how to <strong>communicate with the user researcher or cognitive scientist</strong>, work on the findings, reframe problems and ideate. You collaborate with the researcher throughout the project;</p>
              <p className="lead">You know when to follow and <strong>when to deviate from best practices</strong>. You have <strong>good experience working with complex applications</strong> and are aware that you can't always transpose patterns from generalist products;</p>
              <p className="lead">You help clients to understand the value of UX methodology and build trust, <strong>by having constant communication</strong> with them. You're not working for them <strong>but with them;</strong></p>
              <p className="lead">You know that during the ideation phase, more is more. You are a big believer in divergent thinking where you don't miss the chance to <strong>generate as many ideas as possible;</strong></p>
              <p className="lead">You might be a great designer but you know that your problem-solving skills can be applied to any discipline. You're inventive in your approach and sometimes use unconventional methods or atypical inspirations to tackle challenges;</p>
              <p className="lead">In that regard, User experience, Interface, or Visual design, are means to achieve the bigger goal.</p>

              {/* <img
                className="mb-5"
                src="https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
              />
               */}
              <h2 className="mt-6 mb-3">What we offer</h2>
                <ul className="lead mb-5">
                <li className="mb-2">Working remotely (forever);</li>
                <li className="mb-2">No micromanagement;</li>
                <li className="mb-2">Competitive salary;</li>
                <li className="mb-2">Lead design projects and play a decisive role in shaping our services;</li>
                <li className="mb-2">Working on complex &amp; challenging products. We know you might be tired of designing another yoga app or onboarding flow;</li>
                <li className="mb-2">Working directly with a UX researcher. No more pseudo-UX or guesswork;</li>
                <li className="mb-2">Direct communication with clients;</li>
                <li className="mb-2">Talent Development program to grow professionally &ndash; You will not be stuck in your position or salary.</li>
                </ul>
              {/* <p className="lead mb-2">
                <strong>Responsibilities:</strong>
              </p>
              <ul className="lead mb-5">
                <li>Conduct UX Audit & Heuristic Evaluation</li>
                <li>Define concepts according to tasks and business requirements</li>
                <li>Create UX Strategies</li>
                <li>Lo-fi sketches</li>
                <li>Hi-fi Wireframes</li>
                <li>Clickable prototypes</li>
                <li>UI Concepts & Mood boards</li>
                <li>Hi-fi UI</li>
                <li>GUI Library</li>
                <li>Specs for Devs</li>
              </ul> */}

              {/* <p className="lead mb-2">
                <strong>Requirements:</strong>
              </p>
              <ul className="lead mb-5">
                <li>5+ year of UX or Product design hands-on experience</li>
                <li>Experience designing data heavy / B2B products</li>
                <li>English C1</li>
                <li>Proficiency in Figma</li>
                <li>
                  Being a problem-solver. You love finding out you're wrong.
                </li>
                <li>
                  You consume large amounts of qualitative and quantitative data
                  to constantly refine your assumptions.
                </li>
                <li>
                  Communicating about any questions/problems/ideas/suggestions,
                  asking for feedback and sharing feedback easily.
                </li>
                <li>
                  Being in charge of tasks, to know and tell others about
                  ability or disability to fit the time limit
                </li>
              </ul> */}

              {/* <p className="lead mb-2">
                <strong>Benefits:</strong>
              </p>
              <ul className="lead mb-5">
                <li className="mb-1">100% Remote</li>
                <li className="mb-1">No micromanagement</li>
                <li className="mb-1">Balanced workload</li>
                <li className="mb-1">Access to our Talent Development Program</li>
              </ul> */}

              <h2 className="mt-6 mb-3">Your profile</h2>
              <ul className="lead mb-5">
                <li className="mb-2">You have 7+ years of experience as a digital product designer;</li>
                <li className="mb-2">You've designed complex and data-heavy apps;</li>
                <li className="mb-2">You have experience leading design projects;</li>
                <li className="mb-2">Minimum English level: C1;</li>
                <li className="mb-2">You're a good communicator.</li>
              </ul>

              <Link
                to="https://adamfard.freshteam.com/jobs/R5LbBFdn3r-s/senior-product-designer-ux-ui-remote#applicant-form"
                className="btn btn-lg btn-primary lead-cta"
                target="_blank"
              >
                Apply Now
              </Link>

            </div>

          </div>
        </div>

      


      </section>
    </div>

  
  </Layout>
);

export default SenioruiuxdesignerPage;

export const query = graphql`
  query SenioruiuxdesignerPage {
    datoCmsPage(slug: { eq: "senior-ui-ux-designer-job" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
